var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"list px-3 mx-auto",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-data-table',{staticClass:"iconTable",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.partners.rows,"disable-pagination":"","hide-default-footer":true,"loading":_vm.fetchingData,"loading-text":'Cargando... Espere por favor',"no-data-text":'datos no disponibles',"item-key":"_id","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_vm._l((headers),function(header){return [_c('th',{key:header.value,class:header.align},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticStyle:{"color":"#000"}},[_vm._v(" "+_vm._s(header.text)+" "),(header.text !== 'Detalle')?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-arrow-down-drop-circle ")]):_c('v-icon')],1)])],1)],1)]})],2)])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('td',{style:(_vm.partners.rows.indexOf(item) % 2 == 0
              ? 'background:#eeee!important'
              : '')},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.code",fn:function(ref){
              var item = ref.item;
return [_c('td',{style:(_vm.partners.rows.indexOf(item) % 2 == 0
              ? 'background:#eeee!important'
              : '')},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item.names",fn:function(ref){
              var item = ref.item;
return [_c('td',{style:(_vm.partners.rows.indexOf(item) % 2 == 0
              ? 'background:#eeee!important'
              : '')},[_vm._v(" "+_vm._s(item.names)+" ")])]}},{key:"item.last_name",fn:function(ref){
              var item = ref.item;
return [_c('td',{style:(_vm.partners.rows.indexOf(item) % 2 == 0
              ? 'background:#eeee!important'
              : '')},[_vm._v(" "+_vm._s(item.last_name)+" ")])]}},{key:"item.document_number",fn:function(ref){
              var item = ref.item;
return [_c('td',{style:(_vm.partners.rows.indexOf(item) % 2 == 0
              ? 'background:#eeee!important'
              : '')},[_vm._v(" "+_vm._s(item.document_number)+" ")])]}},{key:"item.detail",fn:function(ref){
              var item = ref.item;
return [_c('td',{style:(_vm.partners.rows.indexOf(item) % 2 == 0
              ? 'background:#eeee!important'
              : '')},[_c('a',{staticStyle:{"color":"#479b25"},on:{"click":function($event){return _vm.$router.push({
                name: 'socios-detalle',
                params: { id: item.id },
              })}}},[_vm._v("Ver más ")])])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.pageSizes,"label":"Items per Page"},on:{"change":_vm.handlePageSizeChange},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('v-pagination',{attrs:{"length":_vm.partners.totalPages,"total-visible":"7","next-icon":"mdi-menu-right","prev-icon":"mdi-menu-left"},on:{"input":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }