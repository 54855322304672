<template>
  <v-card-text class="pa-1">
    <v-container class="pa-2" fluid>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-card-title class="pa-2">
              <v-row dense>
                <v-spacer />
                <v-btn
                  dense
                  color="#479b25"
                  dark
                  class="ma-3"
                  :to="{ name: 'socios-add' }"
                >
                  Crear nuevo socio
                </v-btn>
              </v-row>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
              <table-partner></table-partner>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card-text>
</template>

<script>
import TablePartner from "@/components/tables/TablePartner.vue";

export default {
  name: "partner-table",
  components: { TablePartner },
  data: () => ({}),
};
</script>

<style scoped>
</style>
