<template>
  <v-card height="100%" tile class="pa-10">
    <v-tabs align-with-title>
      <v-tabs-slider color="primary" />
      <v-tab exact @click="$router.push('/socios?status=1')">
        Socios Activos
      </v-tab>
      <v-tab exact @click="$router.push('/socios?status=0')">
        Socios Inactivos
      </v-tab>
    </v-tabs>
    <partner-table></partner-table>
  </v-card>
</template>


<script>
import PartnerTable from "@/views/partner/PartnerTable.vue";
export default {
  name: "partners-index",
  components: { PartnerTable },
  data: () => ({}),
  methods: {},
};
</script>

<style scoped>
</style>